import UserContext from "Context/UserProvider";
import { useContext, useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { weekDays as initialWeekDays } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";
import "./ChargingPartnerPage.css";

function ChargingPartnerRequestPage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);
    const location = useLocation();
    const [isDisabled, setIsDisabled] = useState(false);

    const [rateCardOptions, setRateCardOptions] = useState({ label: "None", value: "NONE", name: "rateCardField" }) as any;
    const [rateCardValue, setRateCardValue] = useState({ label: "None", value: "", name: "rateCardField" }) as any;
    const navigate = useNavigate();


    // Accessing the state passed through navigation
    const state = location.state as { chargingPartnerUuid: string; chargingPartnerBusinessName: string, workflowState: string, businessCategory: string, canOfferCharging: boolean, canRequestCharging: boolean, partnershipRequestType: string, businessUuid: string, chargingPartnerBusinessUuid: string };

    // Now you can use state.chargingPartnerUuid and state.chargingPartnerBusinessName
    // For example:
    const workflowState = state?.workflowState;
    const canOfferCharging = state?.canOfferCharging;
    const canRequestCharging = state?.canRequestCharging;
    const [frequencyOptions, setFrequencyOptions] = useState({ label: "ONCE", value: "ONCE", name: "frequencyField" }) as any;
    const [frequencyValue, setFrequencyValue] = useState({ label: "ONCE", value: "ONCE", name: "frequencyField" }) as any;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);


    useEffect(() => {

        if (state?.partnershipRequestType !== "REQUEST") {
            fetchChargingPartner();
        }
        setLoading(false);

    }, []);


    function onChange(e: any) {

        if (e.name === "rateCard") {


            const selectedRateCard = rateCardOptions.find((i: any) => i.uuid === e.uuid);

            if (selectedRateCard != null) {
                // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
                const selectedValue = {
                    label: selectedRateCard.name, // assuming it has a 'name' field for the label
                    value: selectedRateCard.uuid, // the unique identifier, used as value
                    ...selectedRateCard, // spread the rest of the object properties if there are any others
                };
                setRateCardValue(selectedValue);

            }
        } else if (e.name === "frequencyField") {

            const selectedFrequency = frequencyOptions.find((i: any) => i.value === e.value);

            const selectedValue = {
                label: selectedFrequency.name, // assuming it has a 'name' field for the label
                value: selectedFrequency.value, // the unique identifier, used as value
                ...selectedFrequency, // spread the rest of the object properties if there are any others
            };
            setFrequencyValue(selectedValue);

        }
    }

    async function fetchChargingPartner() {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["accountUuid"] = userInfo.accountUuid;


        let fetch_link = `${rootUrl}/business/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();
        setRateCardOptions([{ label: "None", value: "", name: "rateCard" }, ...res_json.business.rateCards.map((e: any) => ({ ...e, label: e.name, value: e.uuid, name: "rateCard" }))]);
        setFrequencyOptions(res_json.business.frequencies.map((e: any) => ({ ...e, label: e, value: e, name: "frequency" })));

        const options = res_json.business.frequencies.map((frequency: string) => ({ // Specify type of frequency
            label: frequency,
            value: frequency,
            name: "frequencyField"
        }));
        setFrequencyOptions(options);


        if (options.length > 0) {

            setFrequencyValue(options[0]);


        }

        setLoading(false);
    }

    async function submitChargingPartnership() {

        setIsDisabled(true);
        let bodyData: { [name: string]: any } = {};
        // let chargingPartner: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;

        // requesting from the other business
        if (state.partnershipRequestType === "REQUEST") {
            bodyData["chargingPartnerBusinessUuid"] = state.businessUuid;
            bodyData["businessUuid"] = state.chargingPartnerBusinessUuid;

        // offering 
        } else if (state.partnershipRequestType === "OFFER") {
            bodyData["chargingPartnerBusinessUuid"] = state.chargingPartnerBusinessUuid;
            bodyData["businessUuid"] = state.businessUuid;
        }

        bodyData["selectedRateCardUuid"] = rateCardValue.uuid;
        bodyData["partnershipRequestType"] = state.partnershipRequestType;
        bodyData["selectedFrequency"] = frequencyValue.value;

        let fetch_link = `${rootUrl}/charging-partner/request`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        let res_json = await response.json();


        if (response.status === 200) {
            setIsDisabled(false);
            toast.success('Charging Partner Submit Successful', {
                position: 'bottom-center',
                duration: 5000,
            });

            navigate("/share/business/charging-partners");

        } else if (response.status === 400) {
            setIsDisabled(false);

            switch (res_json.code) {
                case "CHARGING_PARTNER_RATE_CARD_UUID_IS_MANDATORY":
                    setErrorMessage("Please select a Rate Card from the list");
                    break;
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst saving this business.");
                    break;
            }


        }

    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    return (
        <>

            {loading ? (
                <>Loading ...</>
            ) : (

                <div className="container-fluid g-0">
                    <div className="row gy-2">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="dashboard-card" >
                                <div className="dashboard-card-title">
                                    <span className="icon material-symbols-outlined">view_timeline</span>
                                    Request Type
                                </div>
                                <div
                                    className="dashboard-card-content"
                                    style={{ gap: 20 }}>
                                    <div className="box-info">
                                        <div className="radio-options">
                                            {(canRequestCharging && state.partnershipRequestType === 'REQUEST') && (
                                                <div>
                                                    <label
                                                        className="item"
                                                        style={{
                                                            width: 300,
                                                            color: workflowState === 'REQUESTED' ? 'grey' : 'initial', // Grey out the label text if the state is REQUESTED
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="partnershipRequestType"
                                                            value="REQUEST"
                                                            defaultChecked={canRequestCharging && !canOfferCharging}
                                                            checked={state.partnershipRequestType === 'REQUEST'}
                                                            // checked={canRequestCharging && !canOfferCharging}
                                                            readOnly
                                                            disabled={workflowState === 'REQUESTED'} // Disable the radio button if the state is REQUESTED
                                                        />
                                                        Request Charging Services
                                                    </label>
                                                </div>
                                            )}
                                            {(canOfferCharging && state.partnershipRequestType === 'OFFER') && (
                                                <div>
                                                    <label className="item" style={{ width: 300 }}>
                                                        <input
                                                            type="radio"
                                                            name="partnershipRequestType"
                                                            value="OFFER"
                                                            checked={state.partnershipRequestType === 'OFFER'}
                                                            readOnly
                                                        />
                                                        Offer Charging Services
                                                    </label>
                                                </div>
                                            )}
                                            {/* 
                                            {(canOfferCharging && canRequestCharging) && (
                                                <div>
                                                    <label
                                                        className="item"
                                                        style={{
                                                            width: 300,
                                                            color: workflowState === 'REQUESTED' ? 'grey' : 'initial', // Grey out the label text if the state is REQUESTED
                                                        }}
                                                    >   <input
                                                            type="radio"
                                                            name="partnershipRequestType"
                                                            value="BOTH"
                                                            checked={partnershipRequestType === 'BOTH'}
                                                            onChange={(e) => setPartnershipRequestType(e.target.value)}
                                                            disabled={workflowState === 'REQUESTED'} // Disable the radio button if the state is REQUESTED

                                                        />
                                                        Both
                                                    </label>
                                                </div>
                                            )} */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {(state.partnershipRequestType === 'OFFER') && (
                            <>
                                <div className="col-xs-12 col-md-6 col-xl-4">
                                    <div className="dashboard-card" >
                                        <div className="dashboard-card-title">
                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                            Rate Card
                                        </div>
                                        <div
                                            className="dashboard-card-content"
                                            style={{ gap: 20 }}>
                                            <div className="box-info">
                                                <span
                                                    className="item"
                                                    style={{ width: 300 }}>
                                                    Rate Card :
                                                </span>
                                                <div style={{ display: "flex", alignItems: "right", textAlign: "left", width: "246px" }}>
                                                    <Select
                                                        value={rateCardValue}
                                                        name="rateCard"
                                                        onChange={onChange}
                                                        options={rateCardOptions}
                                                        styles={{ container: base => ({ ...base, width: "100%" }) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}



                        <div className="col-xs-12 col-md-12 col-xl-12">
                            <div className="">

                                <div
                                    className=""
                                    style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                    <button
                                        className="primary-btn"
                                        disabled={isDisabled}
                                        onClick={submitChargingPartnership}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            )
            }

        </>
    );

}
export default ChargingPartnerRequestPage;