import UserContext from 'Context/UserProvider';
import { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { isChargePointOperator } from "utils/categoryUtils";

import "./App.scss";

// page imports
import BusinessContextProvider from 'Provider/BusinessContextProvider';
import ScenarioConfigContextProvider from "Provider/ScenarioConfigContextProvider";
import ComparisonElectricVehicleTabScreen from "components/fleet/FleetScenarioConfigPageComponent/ComparisonElectricVehicleTabScreen/ComparisonElectricVehicleTabScreen";
import ConfigureTabScreen from "components/fleet/FleetScenarioConfigPageComponent/ConfigureTabScreen/ConfigureTabScreen";
import IceVehiclePage from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/IceVehiclePage";
import IceVehicleRouter from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/IceVehicleRouter";
import UploadIceVehiclePage from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/UploadIceVehiclePage";
import RunPage from "components/fleet/FleetScenarioConfigPageComponent/RunTabScreen/RunPage";
import SurveyRouter from "components/fleet/FleetScenarioConfigPageComponent/SurveyTabScreen/SurveyRouter";
import AddEmailSurvey from "components/user/UserSurveyComponent/AddEmailSurvey";
import AddBusinessFleetPage from "pages/AddBusinessFleetPage/AddBusinessFleetPage";
import AddUserPage from "pages/AddUserPage/AddUserPage";
import AdvancedBookingPage from "pages/AdvancedBookingPage/AdvancedBookingPage";
import AgreementDocumentsEditPage from 'pages/AgreementDocumentsPage/AgreementDocumentsEditPage';
import AgreementDocumentsListPage from 'pages/AgreementDocumentsPage/AgreementDocumentsListPage';
import BusinessChargingNetworkParametersPage from "pages/BusinessChargingNetworkParametersPage/BusinessChargingNetworkParametersPage";
import BusinessDwellingListPage from "pages/BusinessDwellingListPage/BusinessDwellingListPage";
import BusinessInsightPage from "pages/BusinessInsightPage/BusinessInsightPage";
import ChargePointOperatorMapPage from "pages/ChargePointOperator/ChargePointOperatorMapPage";
import ChargingAdvancedBookingsPage from "pages/ChargingAdvancedBookingsPage/ChargingAdvancedBookingsPage";
import LocationChargePointsListPage from "pages/LocationChargePointsListPage/LocationChargePointsListPage";
import PowerPurchaseAgreementListPage from "pages/PowerPurchaseAgreementListPage/PowerPurchaseAgreementListPage";
import RequiredAuth from "pages/RequiredAuth/RequiredAuth";
import RoleAuth from "pages/RoleAuth/RoleAuth";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UserListPage from "pages/UserListPage/UserListPage";
import BuildQuotePage from "pages/ppa-quote/BuildQuotePage";
import BuildQuotePageLocations from "pages/ppa-quote/BuildQuotePageLocations";
import BuildQuotePageResults from "pages/ppa-quote/BuildQuotePageResults";
import BuildQuotePageSummary from "pages/ppa-quote/BuildQuotePageSummary";
import BuildQuotePageVehicles from "pages/ppa-quote/BuildQuotePageVehicles";
import BuildQuoteJourneyInsightPage from "pages/ppa-quote/journey-insight/BuildQuoteJourneyInsightPage";
import TradeListPage from "pages/ppa-trade/TradeListPage";
import PPADetailPage from "pages/ppa/PPADetailPage";
import PPAListPage from "pages/ppa/PPAListPage";
import UnProtectedRouteLayout from "./layout/UnProtectedRouteLayout";
import ChargingPartnerEditPage from "./pages/ChargingPartnerPage/ChargingPartnerEditPage";
import ChargingPartnerListPage from "./pages/ChargingPartnerPage/ChargingPartnerListPage";
import ChargingPartnerMapPage from "./pages/ChargingPartnerPage/ChargingPartnerMapPage";
import ChargingPartnerRequestPage from "./pages/ChargingPartnerPage/ChargingPartnerRequestPage";
import ChargingPartnerReviewOfferPage from "./pages/ChargingPartnerPage/ChargingPartnerReviewOfferPage";
import ChargingPartnerRateCardEditPage from "./pages/ChargingPartnerRateCardPage/ChargingPartnerRateCardEditPage";
import ChargingPartnerRateCardListPage from "./pages/ChargingPartnerRateCardPage/ChargingPartnerRateCardListPage";
import ChargingTransactionListPage from "./pages/ChargingTransactionPage/ChargingTransactionListPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import ElectricVehicleEditPage from "./pages/ElectricVehiclePage/ElectricVehicleEditPage";
import ElectricVehicleListPage from "./pages/ElectricVehiclePage/ElectricVehicleListPage";
import JourneyInsightPage from "./pages/JourneyInsightPage/JourneyInsightPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SiteAccessPolicyEditPage from "./pages/SiteAccessPolicyPage/SiteAccessPolicyEditPage";
import SiteAccessPolicyListPage from "./pages/SiteAccessPolicyPage/SiteAccessPolicyListPage";
import UnauthenticatedSurveyPage from "./pages/SurveyPage/UnauthenticatedSurveyPage";
import UserSurveyPage from "./pages/UserSurveyPage/UserSurveyPage";
import { businessLocationRoutes, fallbackRoute, insightRoutes, unprotectedRoutes } from './routesConfig';
import DwellingDetailPage from 'pages/DwellingDetailPage/DwellingDetailPage';
import ChargingInfrastructurePage from 'pages/ChargingInfrastructurePage/ChargingInfrastructurePage';


export default function AppRoutes(props: any) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);

    return (

        <Routes>
            {/* Unprotected routes */}
            <Route element={<UnProtectedRouteLayout />}>
                {unprotectedRoutes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
            </Route>

            {/* Fallback route */}
            <Route path={fallbackRoute.path} element={fallbackRoute.element} />

            <Route
                path="/plan"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["PLAN"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />
                    <Route
                        path="dashboard"
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />

                    <Route path="business">

                        {businessLocationRoutes.map(({ path, element }) => (
                            <Route key={path} path={path} element={element} />
                        ))}
                        
                        <Route path="location/detail/location-insight">
                            <Route
                                path=""
                                element={<BusinessContextProvider />}>

                                <Route path=""
                                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                    <Route
                                        path=""
                                        element={<DwellingDetailPage error={[error, setError]} />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="location/detail/charging-infrastructure-insight">
                            <Route
                                path=""
                                element={<BusinessContextProvider />}>

                                <Route path=""
                                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                    <Route
                                        path=""
                                        element={<ChargingInfrastructurePage error={[error, setError]} />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route
                            path="surveys"
                            element={<SurveyRouter error={[error, setError]} />}>
                            <Route
                                path=""
                                element={<UserSurveyPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["PLAN"]} />}>
                                <Route
                                    path="send-out-survey"
                                    element={<AddEmailSurvey error={[error, setError]} />}
                                />
                                <Route
                                    path="add-survey-response"
                                    element={<AuthenticatedSurveyPage error={[error, setError]} />}
                                />
                                <Route
                                    path="edit"
                                    element={<AuthenticatedSurveyPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="insight"
                            element={<BusinessInsightPage error={[error, setError]} />}
                        />

                    </Route>


                    <Route path="insight">
                        {insightRoutes.map(({ path, element }) => (
                            <Route key={path} path={path} element={element} />
                        ))}
                    </Route>
                    <Route
                        path="scenario/configuration"
                        element={
                            <ScenarioConfigContextProvider
                                loading={[loading, setLoading]}
                                error={[error, setError]}
                            />
                        }>
                        <Route
                            path=""
                            element={<IceVehicleRouter error={[error, setError]} />}>
                            <Route
                                path=""
                                element={<IceVehiclePage error={[error, setError]} />}
                            />
                            <Route
                                path="upload"
                                element={<UploadIceVehiclePage error={[error, setError]} />}
                            />
                        </Route>

                        <Route
                            path="ice-vehicles"
                            element={<IceVehicleRouter error={[error, setError]} />}>

                            <Route
                                path=""
                                element={<IceVehiclePage error={[error, setError]} />}
                            />

                            <Route
                                path=""
                                element={<RoleAuth />}>
                                <Route
                                    path="upload"
                                    element={<UploadIceVehiclePage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="ev-vehicles"
                            element={<ComparisonElectricVehicleTabScreen error={[error, setError]} />}
                        />

                        <Route
                            path="parameters"
                            element={<ConfigureTabScreen error={[error, setError]} />}
                        />
                        <Route
                            path=""
                            element={<RoleAuth />}>
                            <Route
                                path="run"
                                element={<RunPage error={[error, setError]} />}
                            />
                        </Route>
                    </Route>

                </Route>
            </Route>

            <Route
                path="/share"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["SHARE"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />
                    <Route path="business">
                        <Route path="charging-infrastructure/dwellings">
                            <Route path=""
                                element={<BusinessDwellingListPage error={[error, setError]} />}
                            />
                            <Route
                                path="charge-points/list"
                                element={<LocationChargePointsListPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="site-access-policies">
                            <Route path=""
                                element={<SiteAccessPolicyListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<SiteAccessPolicyEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="agreement-documents">
                            <Route path=""
                                element={<AgreementDocumentsListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<AgreementDocumentsEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-partners">
                            <Route path=""
                                element={<ChargingPartnerListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ChargingPartnerEditPage error={[error, setError]} />}
                                />
                                <Route
                                    path="request"
                                    element={<ChargingPartnerRequestPage error={[error, setError]} />}
                                />
                                <Route
                                    path="offer/review"
                                    element={<ChargingPartnerReviewOfferPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-partners/map">
                            <Route path=""
                                element={<ChargingPartnerMapPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="charging-partners/rate-cards">
                            <Route path=""
                                element={<ChargingPartnerRateCardListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ChargingPartnerRateCardEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-transactions">
                            <Route path=""
                                element={<ChargingTransactionListPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="electric-vehicles">
                            <Route path=""
                                element={<ElectricVehicleListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ElectricVehicleEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="partner-charging-network">
                            <Route path=""
                                element={<BusinessChargingNetworkParametersPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="make-advanced-booking">
                            <Route
                                path=""
                                element={<BusinessContextProvider />}>

                                <Route path=""
                                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                    <Route
                                        path=""
                                        element={<AdvancedBookingPage error={[error, setError]} />}
                                    />
                                </Route>
                            </Route>
                        </Route>

                        <Route
                            path="advanced-bookings"
                            element={<BusinessContextProvider />}>

                            <Route path=""
                                element={<ChargingAdvancedBookingsPage error={[error, setError]} />}
                            />
                        </Route>

                    </Route>
                </Route>
            </Route>
            <Route
                path="/common"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path="user"
                    element={<RoleAuth />}>
                    <Route
                        path="list"
                        element={<UserListPage error={[error, setError]} />}
                    />

                    <Route
                        path=""
                        element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                        <Route
                            path="add"
                            element={<AddUserPage error={[error, setError]} />}
                        />
                    </Route>
                </Route>
                <Route
                    path="profile"
                    element={<ProfilePage error={[error, setError]} />}
                />
                <Route
                    path=""
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                    <Route
                        path="add-business-fleet"
                        element={<AddBusinessFleetPage error={[error, setError]} />}
                    />
                </Route>

                {/* <Route path='infrastructure/dwelling-detail' element={<DwellingDetailPage />} /> */}
                {/* <Route path='infrastructure/add-depot' element={<AddDeportLocationPage />} /> */}
                {/* <Route path='insight/fleet/scenario/categories/vehicle-insight' element={<InsightPage />} /> */}
                {/* <Route path='insight/fleet/scenario/categories' element={<FleetInsight />} /> */}
                <Route
                    path="insight/fleet/scenario/categories/vehicle-insight/journey-insight"
                    element={<JourneyInsightPage error={[error, setError]} />}
                />
            </Route>
            <Route
                path="user-survey"
                element={<UnProtectedRouteLayout />}>
                <Route
                    path="*"
                    element={<UnauthenticatedSurveyPage />}
                />
            </Route>
            <Route
                path="/agree"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["AGREE"]} />}>
                    {isChargePointOperator(userInfo.businessCategory) ? (

                        <Route
                            path=""
                            element={
                                <DashboardPage
                                    name={"dashboard"}
                                    error={[error, setError]}
                                />

                            }
                        />
                    ) : (
                        <Route
                            path=""
                            element={
                                <PPAListPage
                                    error={[error, setError]}
                                />

                            }
                        />
                    )}
                </Route>
                <Route path="ppa"
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["AGREE"]} />}>
                    <Route
                        path=""
                        element={<PPAListPage error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build"
                        element={<BuildQuotePage error={[error, setError]} />}
                    />

                    <Route
                        path="quote/build/vehicles"
                        element={<BuildQuotePageVehicles error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/locations"
                        element={<BuildQuotePageLocations error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/summary"
                        element={<BuildQuotePageSummary error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/results"
                        element={<BuildQuotePageResults error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/journey-insight"
                        element={<BuildQuoteJourneyInsightPage error={[error, setError]} />}
                    />
                    <Route
                        path="list"
                        element={<PPAListPage error={[error, setError]} />}
                    />
                    <Route
                        path="detail"
                        element={<PPADetailPage error={[error, setError]} />}
                    />
                    <Route
                        path="trade/list"
                        element={<TradeListPage error={[error, setError]} />}
                    />
                </Route>
            </Route>

            <Route
                path="/charge"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["CHARGE"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />

                        }
                    />
                    <Route path="charge-point-operator">
                        <Route path="rate-cards"
                            element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["CHARGE"]} />}
                        >
                            <Route
                                path=""
                                element={<ChargingPartnerRateCardListPage error={[error, setError]} />} />
                            <Route
                                path="edit"
                                element={<ChargingPartnerRateCardEditPage error={[error, setError]} />} />

                        </Route>
                        <Route path="map"
                            element={<ChargePointOperatorMapPage error={[error, setError]} />}>
                        </Route>
                        <Route path="ppa/businesses"
                            element={<PowerPurchaseAgreementListPage error={[error, setError]} />}>
                        </Route>


                    </Route>

                    {/* <Route path="rate-cards"
                        element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["CHARGE"]} />}
                    >
                        <Route
                            path=""
                            element={<ChargingPartnerRateCardListPage error={[error, setError]} />} />
                        <Route
                            path="edit"
                            element={<ChargingPartnerRateCardEditPage error={[error, setError]} />} />

                    </Route> */}
                    <Route path="map"
                        element={<ChargePointOperatorMapPage error={[error, setError]} />}>
                    </Route>
                    <Route path="businesses"
                        element={<PowerPurchaseAgreementListPage error={[error, setError]} />}>
                    </Route>
                </Route>
                {/* <Route path="power-purchase-agreement"
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["CHARGE"]} />}>
                    <Route
                        path=""
                        element={<PowerPurchaseAgreementPage error={[error, setError]} />}
                    />

                    <Route
                        path="create"
                        element={<PowerPurchaseAgreementCreatePage error={[error, setError]} />}
                    />
                    <Route
                        path="advanced/create"
                        element={<PowerPurchaseAgreementAdvancedCreatePage error={[error, setError]} />}
                    />
                    <Route
                        path="cpo"
                        element={<PowerPurchaseAgreementCPOPage />}
                    />
                </Route> */}
                <Route path="ppa"
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["CHARGE"]} />}>
                    <Route
                        path=""
                        element={<PPAListPage error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build"
                        element={<BuildQuotePage error={[error, setError]} />}
                    />

                    <Route
                        path="quote/build/vehicles"
                        element={<BuildQuotePageVehicles error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/locations"
                        element={<BuildQuotePageLocations error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/summary"
                        element={<BuildQuotePageSummary error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/results"
                        element={<BuildQuotePageResults error={[error, setError]} />}
                    />
                    <Route
                        path="quote/build/journey-insight"
                        element={<BuildQuoteJourneyInsightPage error={[error, setError]} />}
                    />
                    <Route
                        path="list"
                        element={<PPAListPage error={[error, setError]} />}
                    />
                    <Route
                        path="trade/list"
                        element={<TradeListPage error={[error, setError]} />}
                    />
                </Route>
            </Route>

        </Routes>
    );
}
