import UserContext from "Context/UserProvider";
import { useContext, useEffect, useState } from "react";
import SiteAccessPolicyTable from "../../components/charging/SiteAccessPolicyComponent/SiteAccessPolicyTable";
import AgreementDocumentSetTable from "components/charging/SiteAccessPolicyComponent/AgreementDocumentSetTable";

function AgreementDocumentsListPage(props: any) {
  const [showCategory, setShowCategory] = useState([true, false, false]) as any;
  const [showSecondaryCategory, setShowSecondaryCategory] = useState([true, false, false]);
  const {userInfo} = useContext(UserContext);
  const [documentSets, setDocumentSets] = useState() as any;
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState("All");

  const fetchDocumentSets = async () => {
    let bodyData: { [name: string]: string } = {};

    bodyData["uuid"] = userInfo.uuid;
    bodyData["businessUuid"] = userInfo.businessUuid;
    const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
    let fetch_link = `${rootUrl}/charging-partner/agreement-document-set/list`;
    let fetch_option = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyData),
    };
    let response = await fetch(fetch_link, fetch_option);
    if (response.status !== 200) {
      setIsError(true);
      return;
    }
    let res_json = await response.json();
    setDocumentSets(res_json.documentSets.map((e: any) => ({ ...e, id: e.uuid, name: e.name })));

    console.log("documentSets " + JSON.stringify(documentSets));

    setLoading(false);
  };

  useEffect(() => {
    fetchDocumentSets();
  }, []);
  
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
         
          {showCategory[0] && (
            <>
              <div style={{ display: "flex", gap: "10px", fontWeight: "bold" }}>
                <div className="vl"></div>
                <button
                  className={`tab-btn ${activeMenu === "All" ? "active" : ""}`}
                  onClick={() => (setShowSecondaryCategory([true, false, false]), setActiveMenu("All"))}>
                  All
                </button>
               </div>
              <br />
              {showSecondaryCategory[0] && (
                <>
                  <AgreementDocumentSetTable documentSets={documentSets} />
                </>
              )}

            </>
          )}
          {/* {showCategory[1] &&
                <>
                    <br />
                    <h1>Email Address</h1>
                    <br />
                    <SurveyEmailTable />
                </>
            } */}
        </div>
      )}
    </>
  );
}

export default AgreementDocumentsListPage;
