import DocumentsTable from 'components/common/DocumentsTable';
import TransferListComponent from 'components/common/TransferListComponent';
import { CloudStorageObject } from 'interfaces/CloudStorageObject';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { ChangeEvent, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import UserContext from '../../Context/UserProvider';
import QuestionTable from "../../components/charging/SiteAccessPolicyComponent/QuestionTable";
import './SiteAccessPolicy.css';
import Select from "react-select";

type SignedUrlResponse = {
    ramsDocuments: CloudStorageObject[];
};

type Question = {
    id: string;
    text: string;
    type: string;

}

interface Item {
    uuid: string;
    name: string;
}

function SiteAccessPolicyEditPage(props: any) {

    const { userInfo } = useContext(UserContext)
    const [questions, setQuestions] = useState<Question[]>([]);
    const [ramsDocuments, setRamsDocuments] = useState<CloudStorageObject[]>([]);
    const [missingInput, setMissingInput] = useState("")
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const location = useLocation() as any;
    const [siteAccessPolicyUuid, setSiteAccessPolicyUuid] = useState(location.state?.siteAccessPolicyUuid);

    const [left, setLeft] = useState<Item[]>([]); // Explicit type
    const [right, setRight] = useState<Item[]>([]); // Explicit type

    useEffect(() => {
        if (location.state?.siteAccessPolicyUuid) {
            setSiteAccessPolicyUuid(location.state.siteAccessPolicyUuid);
        }
    }, [location.state?.siteAccessPolicyUuid]);

    const initialState = {
        surveyName: "",
    }

    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [videoFiles, setVideoFiles] = useState<CloudStorageObject[]>([]);
    const [scopeValue, setScopeValue] = useState({}) as any;
    const [surveyName, setSurveyName] = useState("");


    useEffect(() => {
        // Initialize document and video files separately from ramsDocuments
        // setDocumentFiles(ramsDocuments?.filter(doc => doc.type === 'DOCUMENT'));
        setVideoFiles(ramsDocuments);
    }, [ramsDocuments]);

    const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles: CloudStorageObject[] = Array.from(event.target.files).map((file, index) => ({
                id: `temp-${Date.now()}-${index}`, // Temporary unique ID
                name: file.name,
                status: 'Not Saved' as 'Not Saved', // Custom status to indicate the file is not yet saved
                file: file, // Store the file object for uploading
                putSignedUrl: "",
                deleteSignedUrl: "",
                fullName: ""
            }));

            // Add new files to the start of the array
            // setRamsDocuments(prev => [...newFiles, ...prev]);


            setRamsDocuments(prev => {
                return [...newFiles, ...prev];
            });
        }
    };

    const removeDocument = (id: string) => {
        setVideoFiles(prev => prev.filter(doc => doc.id !== id));
    };

    const save = async () => {
        setIsUploading(true); // Indicate the start of the upload process

        const validationStatus = validateForm()
        if (!validationStatus) {
            setIsUploading(false);
            return null; // Return null if validation fails
        }

        // Merge documentFiles and videoFiles to create a new snapshot of ramsDocuments
        const updatedRamsDocuments = [...checking_null_undefined(videoFiles)];

        try {
            // Handle deletion of documents marked as 'Deleted'
            const toDelete = updatedRamsDocuments.filter(doc => doc.status === 'Deleted');
            const deletePromises = toDelete.map(doc =>
                fetch(doc.deleteSignedUrl, {
                    method: 'DELETE',
                })
            );
            await Promise.all(deletePromises);

            // Remove deleted documents from the updatedRamsDocuments before upload operations
            let postDeleteDocuments = updatedRamsDocuments.filter(doc => doc.status !== 'Deleted');

            // Call submitSurvey here to ensure it's always executed
            const surveyResult = await submitSurvey();

            console.log("surveyResult " + surveyResult);

            // Filter out 'Not Saved' documents that have a file to upload
            const toUpload = postDeleteDocuments.filter(doc => doc.status === 'Not Saved' && doc.file);

            if (toUpload.length > 0) {
                const fileInfos = toUpload.map(doc => ({ name: doc.name}));

                const urls = await fetchSignedUrls(fileInfos, surveyResult);

                const uploadPromises = urls.map((url, index) => {
                    const file = toUpload[index].file!; // Non-null assertion is safe due to the filter
                    return fetch(url.putSignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                        },
                    });
                });

                await Promise.all(uploadPromises);

                // Update the status of uploaded documents to 'Saved'
                postDeleteDocuments = postDeleteDocuments.map(doc => ({
                    ...doc,
                    status: doc.status === 'Not Saved' && doc.file ? 'Saved' : doc.status
                }));
            }

            // Set the final state of ramsDocuments
            setRamsDocuments(postDeleteDocuments);

            toast.success('Site Access Policy saved.', {
                position: 'bottom-center',
                duration: 5000,
            });
        } catch (error) {
            setErrorMessage("Error saving Site Access Policy");
            console.error('Error uploading files:', error);
        }

        setIsUploading(false); // Indicate the end of the upload process

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: "smooth" });

    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    // Update the type for files parameter to match the expected structure
    const fetchSignedUrls = async (fileInfos: { name: string; }[], sasUuid: string): Promise<CloudStorageObject[]> => {


        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/videos/signedurls/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ files: fileInfos, businessUuid: userInfo.businessUuid, siteAccessPolicyUuid: sasUuid }),
        };

        let response = await fetch(fetch_link, fetch_option);

        if (response.status !== 200) {
            setIsError(true);
        }

        const data: SignedUrlResponse = await response.json();

        return data.ramsDocuments; // Your backend should respond with an array of URLs
    };

    const updateDocuments = (updatedDocs: CloudStorageObject[]) => {
        setVideoFiles(updatedDocs);
    };

    const addNewQuestion = () => {
        setQuestions((prevQuestions: Question[]) => [
            ...(Array.isArray(prevQuestions) ? prevQuestions : []),
            { id: `${(prevQuestions?.length || 0) + 1}`, text: '', type: 'STATEMENT' }
        ]);
    };

    function checking_null_undefined(array: any) {
        if (array === null || array === undefined) {
            return [];
        }
        return array;
    }

    const fetchSurvey = async () => {
        let bodyData: { [name: string]: string } = {};

        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["siteAccessPolicyUuid"] = siteAccessPolicyUuid;
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        let fetch_link = `${rootUrl}/site-access-policy/get`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return;
        }
        let res_json = await response.json();

        console.log("sap " + JSON.stringify(res_json));

        setLeft(checking_null_undefined(res_json.unSelectedDwellings));
        setRight(checking_null_undefined(res_json.selectedDwellings));
        setQuestions(res_json.survey?.siteInstructions?.map((e: any) => ({ ...e, id: e.uuid, text: e.text, type: e.type })));
        // setRamsDocuments(res_json.ramsDocuments?.map((e: any) => ({ ...e, id: e.fullName, name: e.name, type: e.type, fullName: e.fullName, signedUrl: e.signedUrl })));

        setRamsDocuments(
            res_json.survey?.videos
                ? res_json.survey?.videos?.map((e: any) => ({
                    ...e,
                    id: e.fullName,
                    name: e.name,
                    type: e.type,
                    fullName: e.fullName,
                    signedUrl: e.signedUrl,
                }))
                : []
        );


        setSurveyName(res_json.survey?.name);

        const selectedScope = res_json.scope;

        if (selectedScope != null) {
            // Ensure the object structure is the same as in 'siteAccessPolicyOptions'
            const selectedValue = {
                label: selectedScope, // assuming it has a 'name' field for the label
                value: selectedScope, // the unique identifier, used as value
                ...selectedScope, // spread the rest of the object properties if there are any others
            };
            setScopeValue(selectedValue);

        } else {
            const selectedValue = {
                label: "", // assuming it has a 'name' field for the label
                value: "", // the unique identifier, used as value
            };
            setScopeValue(selectedValue);
        }

        setLoading(false);

    };

    useEffect(() => {
        fetchSurvey();
    }, []);

    function validateForm() {

        if (surveyName === '' || surveyName === undefined) {
            return false
        }

        return true

    }



    const handleQuestionUpdate = useCallback((updatedQuestions: any) => {
        setQuestions(updatedQuestions);
    }, []);

    const submitSurvey = async () => {

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        const bodyData = {
            businessUuid: userInfo.businessUuid,
            uuid: userInfo.uuid,
            surveyName: surveyName,
            questions: questions,
            siteAccessPolicyUuid: siteAccessPolicyUuid,
            ramsDocuments: ramsDocuments,
            scope: scopeValue.value,
            selectedDwellings: right,
        };


        let fetch_link = `${rootUrl}/site-access-policy/save`;
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return null; // Return null in case of HTTP errors
        }
        let res_json = await response.json();

        return res_json.uuid; // Assuming 'uuid' is the name of the key where the UUID is stored
    };



    function onChange(e: any) {
        let action: any

        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            }

        } else {
            action = {
                input: e.name,
                value: e.value,
            }
        }
        if (action.input === "surveyName") {
            setSurveyName(action.value);
        }



    }

    return (
        <>
            {
                isError ? <ErrorPage statusCode='505' />
                    :
                    <>
                        {
                            loading ? <>loading.......</> :
                                <>
                                    <div className="container-fluid g-0">
                                        <div className="row gy-2">
                                            <div className="col-xs-12 col-md-12 col-xl-4">
                                                <div className="dashboard-card">
                                                    <div className="dashboard-card-title">
                                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                                        Site Access Policy Details
                                                    </div>
                                                    <div className="dashboard-card-content" style={{ gap: 20 }}>
                                                        <div className="box-info">
                                                            <span className="item" style={{ width: 300 }}>Site Access Policy Name :</span>
                                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                                <input type="text" defaultValue={surveyName} className="textbox" name="surveyName" onChange={onChange} id='surveyName' required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {(scopeValue.value === 'DWELLING') ? (
                                                <div className="col-xs-12 col-md-12 col-xl-8">
                                                    <div className="dashboard-card">
                                                        <div className="dashboard-card-title">
                                                            <span className="icon material-symbols-outlined">view_timeline</span>
                                                            Locations
                                                        </div>
                                                        <div className="dashboard-card-content" style={{ gap: 20 }}>
                                                            <TransferListComponent
                                                                left={left}
                                                                right={right}
                                                                setLeft={setLeft}
                                                                setRight={setRight}
                                                                leftName="Available"
                                                                rightName="Allocated"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-12 col-xl-12">
                                        <div className="dashboard-card" >
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Instructional Videos
                                            </div>
                                            <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                <div className="box-info">
                                                    <label htmlFor="video-upload" className="action-button">
                                                        Upload Videos
                                                        <input type="file" id="video-upload" multiple onChange={handleVideoChange} style={{ display: 'none' }} accept="video/*" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div className="user-survey-container" style={{ marginTop: 10 }}>
                                                    <DocumentsTable data={videoFiles} onRemove={(id) => removeDocument(id)} onUpdate={(docs) => updateDocuments(docs)} />
                                                    <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="dashboard-card" >
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Driver Instructions
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                                <div className="user-survey-container" style={{ marginTop: 10 }}>


                                                    <QuestionTable data={questions} onUpdate={handleQuestionUpdate}
                                                    />
                                                    <button style={{ width: 140, marginTop: 16 }} className="" onClick={addNewQuestion}>
                                                        Add a question
                                                    </button>

                                                    <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p>


                                                </div>
                                            </div>
                                        </div>


                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, gap: 20 }}>

                                            <button className='primary-btn' onClick={() => save()}>Save</button>
                                        </div>

                                    </div>

                                </>
                        }
                        {
                            isUploading && (
                                <div className="spinner-overlay">
                                    <div className="spinner"></div>
                                </div>
                            )
                        }
                    </>
            }

        </>

    );
}

export default SiteAccessPolicyEditPage;