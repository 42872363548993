import { Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

function AgreementDocumentSetTable(props: any) {
  const navigate = useNavigate();
  const documentSets = props.documentSets;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (cell) => {
        return (
          <span
            className="hyper-link"
            onClick={() =>
              navigate("/share/business/agreement-documents/edit", {
                state: {
                    chargingPartnerAgreementDocumentSetUuid: cell.row.uuid,
                },
              })
            }
          >
            {cell.row.name}
          </span>
        );
      },
    },
  ];

  return (
    <div className="table-box">
      <DataGrid
        rows={documentSets}
        width={"100%"}
        autoHeight
        {...props.data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <span>No Agreement Document Sets</span>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Filter returns no results
            </Stack>
          ),
        }}
        checkboxSelection
      />
    </div>
  );
}

export default AgreementDocumentSetTable;
