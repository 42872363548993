import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { format } from 'date-fns';
import "../../components/common/SiteTemplate/Nav/Nav.scss";


interface Slot {
    bookingMinutes: number;
    actualMinutes: number;
    startTime: string; // ISO 8601 string
    endTime: string;   // ISO 8601 string
    futureBooking: boolean;
}

interface ApiResponse {
    code: string | null;
    fields: any | null;
    slots: Slot[];
    availableUntil: string | null; // ISO 8601 string
    futureBooking: boolean;
}

function BookNowPage(props: any) {
    const [loading, setLoading] = useState(true);
    const [bookingError, setBookingError] = useState(false);

    const [availability, setAvailability] = useState<ApiResponse>({
        code: null,
        fields: null,
        slots: [],
        availableUntil: null,
        futureBooking: false,
    });

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formatEndTime = (endTime: string) => {
        return format(new Date(endTime), 'HH:mm');
    };

    const { id } = useParams(); // Extract the 'id' parameter from the URL

    // Split the 'id' into three parts
    const [dwellingUuid, chargePointUuid, chargePointConnectorUuid] = id
        ? id.split('|')
        : [null, null, null];

    useEffect(() => {
        fetchBookNowAvailability();

    }, []);

    async function fetchBookNowAvailability() {
        setLoading(true);
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["dwellingUuid"] = dwellingUuid;
        bodyData["chargePointUuid"] = chargePointUuid;
        bodyData["chargePointConnectorUuid"] = chargePointConnectorUuid;

        console.log("FETCH BOOK NOW " + JSON.stringify(bodyData));

        let fetch_link = `${rootUrl}/book-now-router/availability/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        if (response.status === 200) {

            let res_json = await response.json();

            setAvailability(res_json);
            setLoading(false);

        } else {
            setBookingError(true);
            setErrorMessage("There has been a problem retrieving booking information");
            setLoading(false);

        }

    }

    async function register() {
        setLoading(true); // Indicate the start of the Load process

        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        console.log(JSON.stringify(bodyData));

        let fetch_link = `${rootUrl}/afp2/register`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };

        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        setLoading(false); // Indicate the start of the Load process

        if (response.status === 200) {
            // resetCaptcha();
            navigate("/afp/register/success");

        } else if (response.status === 400) {
            console.log(JSON.stringify(res_json.code));
            switch (res_json.code) {
                case "REASON_USER_ALREADY_EXISTS":
                    setErrorMessage("It has not been possible to create your account at this time.");
                    break;
                // openModal();
                case "REASON_PASSWORD_TOO_WEAK":
                    setErrorMessage("Sorry password too weak.");
                    break;
                case "MANDATORY_DATA_MISSING":
                    setErrorMessage("Please enter all mandatory fields.");
                    break;
                case "CONTACT_NUMBER_INVALID":
                    setErrorMessage("Please enter a valid contact number");
                    break;
                case "EMAIL_NOT_BUSINESS_DOMAIN":
                    setErrorMessage("Please enter a business email address");
                    break;
                default:
                    setErrorMessage("An error (" + res_json.code + ") occurred whilst saving this business.");
                    break;
            }
        }
    }

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);

    }, [errorMessage]);



    return (
        <>

            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {!bookingError && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 40, width: '100vw' }}>
                    <div className="contact-container" style={{ marginTop: "72px" }}>
                        <div>
                            <div className="container-fluid g-0">
                                <div className="row gy-2 justify-content-center">
                                    <div className="col-xs-12 col-md-8 col-xl-6">
                                        <h1
                                            className="logo"
                                            style={{ cursor: "pointer" }}>
                                            Evata
                                        </h1>
                                        Please click on one of the available options for this charge point
                                        {availability.slots?.map((slot, index) => (

                                            <div style={{ display: "flex", justifyContent: "center", gap: 50, margin: "32px" }}>

                                                <button className="primary-btn" onClick={register}>
                                                    Book {slot.bookingMinutes} minutes
                                                    <br />
                                                    (leave by {formatEndTime(slot.endTime)})
                                                </button>
                                            </div>
                                        ))}
                                        {availability.futureBooking && (
                                            <b>Please note this charge point is available until<br />{availability.availableUntil}</b>
                                        )}
                                        <br />
                                        <br />
                                        <br />
                                        <i>Times are rounded up to the nearest hourly slot</i>

                                    </div>
                                </div >
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
}



export default BookNowPage;