import UserContext from "Context/UserProvider";
import React, { useContext, ReactNode } from "react";
import { Outlet } from "react-router";

interface RoleAuthProps {
  roles?: string[];
  products?: string[];
  children?: ReactNode; // Allow children to be passed
}

const RoleAuth: React.FC<RoleAuthProps> = ({ roles, products, children }) => {
  const { userInfo } = useContext(UserContext);

  const roleOk = !roles || roles.includes(userInfo.userType);
  const productOk = !products || products.some((value) => userInfo.businessProducts?.includes(value));

  if (roleOk && productOk) {
    return <>{children || <Outlet />}</>; // Render children if provided, otherwise render Outlet
  } else {
    return <>This feature is not available for your user role and product.</>;
  }
};

export default RoleAuth;
