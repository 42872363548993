import DocumentsTable from 'components/common/DocumentsTable';
import { CloudStorageObject } from 'interfaces/CloudStorageObject';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import UserContext from '../../Context/UserProvider';
import './AgreementDocuments.css';

type SignedUrlResponse = {
    ramsDocuments: CloudStorageObject[];
};

function AgreementDocumentsEditPage(props: any) {

    const { userInfo } = useContext(UserContext)
    const [ramsDocuments, setRamsDocuments] = useState<CloudStorageObject[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const location = useLocation() as any;
    const [chargingPartnerAgreementDocumentSetUuid, setChargingPartnerAgreementDocumentSetUuid] = useState(location.state?.chargingPartnerAgreementDocumentSetUuid);

    useEffect(() => {

        if (location.state?.chargingPartnerAgreementDocumentSetUuid) {
            setChargingPartnerAgreementDocumentSetUuid(location.state.chargingPartnerAgreementDocumentSetUuid);
        }
    }, [location.state?.chargingPartnerAgreementDocumentSetUuid]);

    const initialState = {
        surveyName: "",
    }

    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [documentFiles, setDocumentFiles] = useState<CloudStorageObject[]>([]);
    const [documentSetName, setDocumentSetName] = useState("");


    useEffect(() => {
        // Initialize document and video files separately from ramsDocuments
        setDocumentFiles(ramsDocuments);
    }, [ramsDocuments]);

    // Use the FileList type for the files state.
    const handleDocumentChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const newFiles: CloudStorageObject[] = Array.from(event.target.files).map((file, index) => ({
                id: `temp-${Date.now()}-${index}`, // Temporary unique ID
                name: file.name,
                status: 'Not Saved' as 'Not Saved', // Custom status to indicate the file is not yet saved
                file: file, // Store the file object for uploading
                putSignedUrl: "",
                deleteSignedUrl: "",
                fullName: ""
            }));

            // Add new files to the start of the array
            setRamsDocuments(prev => [...newFiles, ...prev]);
        }
    };



    const removeDocument = (id: string) => {
        setDocumentFiles(prev => prev.filter(doc => doc.id !== id));
    };

    const save = async () => {
        setIsUploading(true); // Indicate the start of the upload process

        // Merge documentFiles and videoFiles to create a new snapshot of ramsDocuments
        const updatedRamsDocuments = [...documentFiles];
        console.log("ALL RAMS " + JSON.stringify(updatedRamsDocuments));

        try {
            // Handle deletion of documents marked as 'Deleted'
            const toDelete = updatedRamsDocuments.filter(doc => doc.status === 'Deleted');
            const deletePromises = toDelete.map(doc =>
                fetch(doc.deleteSignedUrl, {
                    method: 'DELETE',
                })
            );
            await Promise.all(deletePromises);

            // Remove deleted documents from the updatedRamsDocuments before upload operations
            let postDeleteDocuments = updatedRamsDocuments.filter(doc => doc.status !== 'Deleted');

            // Call submitSurvey here to ensure it's always executed
            const submitResult = await submitDocumentSet();

            console.log("submitResult " + submitResult);

            // Filter out 'Not Saved' documents that have a file to upload
            const toUpload = postDeleteDocuments.filter(doc => doc.status === 'Not Saved' && doc.file);

            if (toUpload.length > 0) {
                const fileInfos = toUpload.map(doc => ({ name: doc.name}));

                const urls = await fetchSignedUrls(fileInfos, submitResult);

                const uploadPromises = urls.map((url, index) => {
                    const file = toUpload[index].file!; // Non-null assertion is safe due to the filter
                    return fetch(url.putSignedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                        },
                    });
                });

                await Promise.all(uploadPromises);

                // Update the status of uploaded documents to 'Saved'
                postDeleteDocuments = postDeleteDocuments.map(doc => ({
                    ...doc,
                    status: doc.status === 'Not Saved' && doc.file ? 'Saved' : doc.status
                }));
            }

            // Set the final state of ramsDocuments
            setRamsDocuments(postDeleteDocuments);

            toast.success('Site Access Policy saved.', {
                position: 'bottom-center',
                duration: 5000,
            });
        } catch (error) {
            setErrorMessage("Error saving Site Access Policy");
            console.error('Error uploading files:', error);
        }

        setIsUploading(false); // Indicate the end of the upload process
    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage, {
                position: 'bottom-center',
                duration: 5000,
            });
        }
        setErrorMessage(null);
    }, [errorMessage]);


    // Update the type for files parameter to match the expected structure
    const fetchSignedUrls = async (fileInfos: { name: string; }[], sasUuid: string): Promise<CloudStorageObject[]> => {


        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;

        let fetch_link = `${rootUrl}/rams/signedurls/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ files: fileInfos, businessUuid: userInfo.businessUuid, siteAccessPolicyUuid: sasUuid }),
        };

        let response = await fetch(fetch_link, fetch_option);

        if (response.status !== 200) {
            setIsError(true);
        }

        const data: SignedUrlResponse = await response.json();

        return data.ramsDocuments; // Your backend should respond with an array of URLs
    };

    const updateDocuments = (updatedDocs: CloudStorageObject[]) => {
            setDocumentFiles(updatedDocs);
    };


    const fetchSurvey = async () => {
        let bodyData: { [name: string]: string } = {};

        if (chargingPartnerAgreementDocumentSetUuid != null) {
            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["chargingPartnerAgreementDocumentSetUuid"] = chargingPartnerAgreementDocumentSetUuid;
            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/charging-partner/agreement-document-set/get`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            console.log(JSON.stringify(res_json));

            setRamsDocuments(res_json.ramsDocuments.map((e: any) => ({ ...e, id: e.fullName, name: e.name, type: e.type, fullName: e.fullName, signedUrl: e.signedUrl })));
            setDocumentSetName(res_json.documentSet.name);
        }


        console.log(JSON.stringify(ramsDocuments));

        setLoading(false);

    };

    useEffect(() => {
        fetchSurvey();
    }, []);

    function validateForm() {

        console.log("documentSetName " + documentSetName);

        if (documentSetName === '') {
            return false
        }

        return true

    }

    const submitDocumentSet = async () => {
        const validationStatus = validateForm()
        if (!validationStatus) {
            return null; // Return null if validation fails
        }

        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        const bodyData = {
            businessUuid: userInfo.businessUuid,
            uuid: userInfo.uuid,
            documentSetName: documentSetName,
            chargingPartnerAgreementDocumentSetUuid: chargingPartnerAgreementDocumentSetUuid,
            ramsDocuments: ramsDocuments,
        };

        console.log("BD " + JSON.stringify(bodyData));

        let fetch_link = `${rootUrl}/charging-partner/agreement-document-set/save`;
        let fetch_option = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            setIsError(true);
            return null; // Return null in case of HTTP errors
        }
        let res_json = await response.json();

        return res_json.uuid; // Assuming 'uuid' is the name of the key where the UUID is stored
    };



    function onChange(e: any) {


        let action: any
        if (e.target) {
            action = {
                input: e.target.name,
                value: e.target.value,
            }

        } else {
            action = {
                input: e.name,
                value: e.value,
            }
        }

        if (action.input === "documentSetName") {
            setDocumentSetName(action.value);
        }

        console.log("action " + JSON.stringify(action));

    }

    return (
        <>
            {
                isError ? <ErrorPage statusCode='505' />
                    :
                    <>
                        {
                            loading ? <>loading.......</> :
                                <>
                                    <div className="container-fluid g-0">
                                        <div className="row gy-2">
                                            <div className="col-xs-12 col-md-12 col-xl-4">
                                                <div className="dashboard-card">
                                                    <div className="dashboard-card-title">
                                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                                        Site Access Policy Details
                                                    </div>
                                                    <div className="dashboard-card-content" style={{ gap: 20 }}>
                                                        <div className="box-info">
                                                            <span className="item" style={{ width: 300 }}>Agreement Document Set Name :</span>
                                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                                <input type="text" defaultValue={documentSetName} className="textbox" name="documentSetName" onChange={onChange} id='documentSetName' required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-12 col-xl-12">
                                        <div className="dashboard-card" >
                                            <div className="dashboard-card-title">
                                                <span className="icon material-symbols-outlined">view_timeline</span>
                                                Partnership / Contractual Documents
                                            </div>
                                            <div
                                                className="dashboard-card-content"
                                                style={{ gap: 20 }}>
                                                <div className="box-info">
                                                    <label htmlFor="document-upload" className="action-button">
                                                        Upload Files
                                                        <input type="file" id="document-upload" multiple onChange={handleDocumentChange} style={{ display: 'none' }} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div className="user-survey-container" style={{ marginTop: 10 }}>
                                                    <DocumentsTable data={documentFiles} onRemove={(id) => removeDocument(id)} onUpdate={(docs) => updateDocuments(docs)} />
                                                    {/* <p style={{ color: 'red', textAlign: 'center', marginTop: 30 }}>{missingInput}</p> */}
                                                </div>
                                            </div>

                                        </div>


                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30, gap: 20 }}>

                                            <button className='primary-btn' onClick={() => save()}>Save</button>
                                        </div>

                                    </div>

                                </>
                        }
                        {
                            isUploading && (
                                <div className="spinner-overlay">
                                    <div className="spinner"></div>
                                </div>
                            )
                        }
                    </>
            }

        </>

    );
}

export default AgreementDocumentsEditPage;