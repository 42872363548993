import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

interface Item {
  uuid: string;
  name: string;
}

interface TransferListProps {
  left: Item[];
  right: Item[];
  setLeft: React.Dispatch<React.SetStateAction<Item[]>>;
  setRight: React.Dispatch<React.SetStateAction<Item[]>>;
  leftName: string;
  rightName: string;
}

function not(a: readonly Item[], b: readonly Item[]) {
  return a.filter((item) => !b.some((bItem) => bItem.uuid === item.uuid));
}

function intersection(a: readonly Item[], b: readonly Item[]) {
  return a.filter((item) => b.some((bItem) => bItem.uuid === item.uuid));
}

export default function TransferListComponent({
    left,
    right,
    setLeft,
    setRight,
    leftName,
    rightName,
  }: TransferListProps) {
    const [checked, setChecked] = React.useState<readonly Item[]>([]);
  
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
  
    const handleToggle = (item: Item) => () => {
      const currentIndex = checked.findIndex((checkedItem) => checkedItem.uuid === item.uuid);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(item);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleAllRight = () => {
      setRight([...right, ...left]);
      setLeft([]);
    };
  
    const handleCheckedRight = () => {
      setRight([...right, ...leftChecked]);
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };
  
    const handleCheckedLeft = () => {
      setLeft([...left, ...rightChecked]);
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };
  
    const handleAllLeft = () => {
      setLeft([...left, ...right]);
      setRight([]);
    };
  
    const customList = (items: readonly Item[], title: string) => (
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ marginBottom: 1, fontWeight: 'normal', fontSize: '12pt' }}>{title}</Box>
        <Paper
          sx={{
            width: 320,
            height: 230,
            overflow: 'auto',
            border: '1px solid #c8c8c8', // Adds black border
            borderRadius: '1px',
            boxShadow: 'none',
          }}
        >
          <List dense component="div" role="list">
            {items.map((item: Item) => {
              const labelId = `transfer-list-item-${item.uuid}-label`;
  
              return (
                <ListItemButton
                  key={item.uuid}
                  role="listitem"
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.some((checkedItem) => checkedItem.uuid === item.uuid)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={item.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Paper>
      </Box>
    );
  
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box>{customList(left, leftName)}</Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
              sx={{ fontSize: '16pt' }}
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              sx={{ fontSize: '16pt' }}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              sx={{ fontSize: '16pt' }}
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
              sx={{ fontSize: '16pt' }}
            >
              ≪
            </Button>
          </Box>
        </Box>
        <Box>{customList(right, rightName)}</Box>
      </Box>
    );
  }
  