import AddNewLocationPage from 'components/business/InfrastructureComponent/AddNewLocationPage';
import ImportLocationPage from 'components/business/LocationComponents/ImportLocationPage';
import BookNowPage from 'pages/booking/BookNowPage';
import ChargingInfrastructurePage from 'pages/ChargingInfrastructurePage/ChargingInfrastructurePage';
import DwellingDetailPage from 'pages/DwellingDetailPage/DwellingDetailPage';
import InfrastructurePage from 'pages/InfrastructurePage/InfrastructurePage';
import LocationChargePointsListPage from 'pages/LocationChargePointsListPage/LocationChargePointsListPage';
import OcmCategoryInsight from 'pages/OcmCategoryInsightPage/OcmCategoryInsightPage';
import RoleAuth from 'pages/RoleAuth/RoleAuth';
import BusinessContextProvider from 'Provider/BusinessContextProvider';
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import withSuspense from 'utils/withSuspense'; // Ensure this file exists and works

const LoginPage = React.lazy(() => import('pages/LoginPage/LoginPage'));
const HomePageBlank = React.lazy(() => import('pages/HomePage/HomePageBlank'));
const ContactPage = React.lazy(() => import('pages/ContactPage/ContactPage'));
const JoinUsPage = React.lazy(() => import('pages/JoinUsPage/JoinUsPage'));
const RegisterPage = React.lazy(() => import('pages/Register/RegisterPage'));
const Membership = React.lazy(() => import('pages/MembershipPage/Membership'));
const SubscriptionPage = React.lazy(() => import('pages/SubscriptionPage/SubscriptionPage'));
const AboutPage = React.lazy(() => import('pages/AboutPage/AboutPage'));
const TermConditionPage = React.lazy(() => import('pages/TermConditionPage/TermConditionPage'));
const FrequenceQuestionPage = React.lazy(() => import('pages/FrequenceQuestionPage/FrequenceQuestionPage'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicy'));
const PowerPurchaseAgreementPublicPage = React.lazy(() => import('pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicPage'));
const PowerPurchaseAgreementPublicSuccessPage = React.lazy(() => import('pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicSuccessPage'));
const AfpRegisterPage = React.lazy(() => import('pages/afp/register/AfpRegisterPage'));
const AfpSuccessPage = React.lazy(() => import('pages/afp/register/AfpSuccessPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage/DashboardPage'));
const ErrorPage = React.lazy(() => import('pages/ErrorPage/ErrorPage'));
const FleetInsight = React.lazy(() => import('pages/FleetInsightPage/FleetInsight'));
const FleetScenarioPage = React.lazy(() => import('pages/FleetScenarioPage/FleetScenarioPage'));
const FleetScenarioSummaryPage = React.lazy(() => import('pages/FleetScenarioSummaryPage/FleetScenarioSummaryPage'));
const InsightPage = React.lazy(() => import('pages/InsightPage/InsightPage'));
const JourneyInsightPage = React.lazy(() => import('pages/JourneyInsightPage/JourneyInsightPage'));
const OperationalCostModelPage = React.lazy(() => import('pages/OperationalCostModelPage/OperationalCostModelPage'));
const OperationalCostModelSummaryPage = React.lazy(() => import('pages/OperationalCostModelSummaryPage/OperationalCostModelSummaryPage'));
const OcmCategoryInsightPage = React.lazy(() => import('pages/OcmCategoryInsightPage/OcmCategoryInsightPage'));

// Route configuration
export const unprotectedRoutes: RouteObject[] = [
  {
    path: '/login',
    element: withSuspense(LoginPage),
  },
  {
    path: '/',
    element: withSuspense(HomePageBlank),
  },
  {
    path: '/contact',
    element: withSuspense(ContactPage),
  },
  {
    path: '/launch',
    element: withSuspense(JoinUsPage),
  },
  {
    path: '/register',
    element: withSuspense(RegisterPage),
  },
  {
    path: '/membership',
    element: withSuspense(Membership),
  },
  {
    path: '/subscription',
    element: withSuspense(SubscriptionPage),
  },
  {
    path: '/about',
    element: withSuspense(AboutPage),
  },
  {
    path: '/term_condition',
    element: withSuspense(TermConditionPage),
  },
  {
    path: '/frequence_question',
    element: withSuspense(FrequenceQuestionPage),
  },
  {
    path: '/privacy_policy',
    element: withSuspense(PrivacyPolicy),
  },
  {
    path: '/ppa',
    element: withSuspense(PowerPurchaseAgreementPublicPage),
  },
  {
    path: '/ppa/success',
    element: withSuspense(PowerPurchaseAgreementPublicSuccessPage),
  },
  {
    path: '/afp/register',
    element: withSuspense(AfpRegisterPage),
  },
  {
    path: '/afp/register/success',
    element: withSuspense(AfpSuccessPage),
  },
  {
    path: '/book-now/:id',
    element: (<BookNowPage />),
  },
];


// Protected routes (authentication required)
export const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: withSuspense(DashboardPage),
  },
];

// Fallback route for unmatched paths
export const fallbackRoute: RouteObject = {
  path: '*',
  element: withSuspense(ErrorPage),
};

export const insightRoutes: RouteObject[] = [
  {
    path: 'operational-cost-model-summary',
    element: withSuspense(OperationalCostModelSummaryPage),
  },
  {
    path: 'operational-cost-model',
    element: withSuspense(OperationalCostModelPage),
  },
  {
    path: 'fleet',
    element: withSuspense(FleetScenarioPage),
  },
  {
    path: 'fleet/scenario/insight',
    element: withSuspense(FleetScenarioSummaryPage),
  }, {
    path: 'fleet/scenario/categories',
    element: withSuspense(FleetInsight),
  }, {
    path: 'fleet/scenario/categories/vehicle-insight',
    element: withSuspense(InsightPage),
  }, {
    path: 'fleet/scenario/categories/vehicle-insight/journey-insight',
    element: withSuspense(JourneyInsightPage),
  }, {
    path: 'operational-cost-model/category',
    element: withSuspense(OcmCategoryInsightPage),
  }
]

export const businessLocationRoutes: RouteObject[] = [
  {
    path: "locations",
    element: <InfrastructurePage />,
  },
  {
    path: "locations/add-location",
    element: (
      <RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["PLAN", "SHARE"]}>
        <AddNewLocationPage />
      </RoleAuth>
    ),
  },
  {
    path: "locations/import-locations",
    element: (
      <RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["PLAN", "SHARE"]}>
        <ImportLocationPage />
      </RoleAuth>
    ),
  },
  {
    path: "location/charge-points/list",
    element: <LocationChargePointsListPage />,
  },
  // {
  //   path: "location/detail/location-insight",
  //      element: (
  //         <BusinessContextProvider>
  //             <Outlet />
  //         </BusinessContextProvider>
  //     ),
  //     children: [
  //         {
  //             path: "",
  //             element: (
  //                 <RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]}>
  //                     <DwellingDetailPage />
  //                 </RoleAuth>
  //             ),
  //         },
  //     ],
  // },
  {
    path: "location/detail/charging-infrastructure-insight",
    element: (
      <BusinessContextProvider>
        <ChargingInfrastructurePage />
      </BusinessContextProvider>
    ),
  },
]

